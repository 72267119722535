import Fetch from "./Interceptor";
import CONFIGS from "../config/config";

Fetch.requestInterceptors.use((config) => {
  config.headers = {
    ...config.headers,
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem(CONFIGS.TOKEN_KEY)}`,
  };
  if (config.options) {
    config = {
      ...config,
      ...config.options,
      "mode": "cors",
    };
  }
  if (config.method !== "GET") {
    config.body = JSON.stringify(config.data);
    delete config.data;
  }
  return config;
});

Fetch.responseInterceptors.use(async (response) => {
  const isJson = response.headers
    .get("Content-Type")
    .includes("application/json");
  const data = isJson ? await response.json() : null;
  if (!response.ok) {
    const error = (response && response.message) || response.statusText;
    return Promise.reject(error);
  }
  return data;
});

class Api {
  constructor(url = "") {
    this.url = url;
  }

  setURL(url) {
    this.url = url;
  }

  async get(resource) {
    try {
      const response = await Fetch(`${this.url}${resource}`, {
        method: "GET",
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async post(resource, body) {
    try {
      const response = await Fetch(`${this.url}${resource}`, {
        method: "POST",
        data: body,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async put(resource, body) {
    try {
      const response = await Fetch(`${this.url}${resource}`, {
        method: "PUT",
        data: body,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async delete(resource, body) {
    try {
      const response = await Fetch(`${this.url}${resource}`, {
        method: "DELETE",
        data: body,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  async patch(resource, body) {
    try {
      const response = await Fetch(`${this.url}${resource}`, {
        method: "PATCH",
        data: body,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}

export default Api;
