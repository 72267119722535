import rawImage from "../assets/images/raw.jpg";
import bulkImage from "../assets/images/bulk.jpg";
import clipinImage from "../assets/images/clipin.jpg";
import closureImage from "../assets/images/closure.jpg";
import cus3Image from "../assets/images/cus2.webp";
export const categories = [
  {
    title: "Raw Virgin Hairs",
    image: rawImage,
    about:
      "The definition of the term is already in its name 'RAW' , meaning the hair is 100% Virgin ie., the pure form of human hair without any    chemicals, perming, color treating it or bleaching.",
    alt: "Raw Hair",
    link: "raw-virgin-hairs",
    description: `The definition of the term is already in its name 'RAW' ,meaning the hair is 100% Virgin ie., the pure form of human hair without any chemicals, perming, color treating it or bleaching. It is 100% Pure natural human hair.

    What makes raw hair more special is that it has its cuticles intact and preserved unidirectional throughout the process, which in other words is 100% REMY Human Hair. Since the hair is raw and hasn't any treatment that strips off the cuticles, the hair has its cuticles attached to it, making it convenient to treat the raw hair extension as one's real human hair.
    
    Please don't get confused that raw hair is from a single donor hair. The name 'raw' simply means that the hair is not treated/altered in any way. However, it could have been collected from a single donor or multi-donor. Hair extensions are available with single donor raw hair or raw hair. Single donor hair is the best. So, while buying your hair extension, don't forget to ask or read the product description to find out whether the hair is single donor hair or multi-donor. Our hair extensions are fully made using single donor hair.`,
    value: "raw-virgin-hairs",
    to: "/categories/raw-virgin-hairs",
  },
  {
    title: "Machine Weft Hairs",
    image: closureImage,
    about:
      "Since its ethically sourced hair from temples. Indian hair gives a wide variety of hair types since India has a diverse culture and food habits and varying climates in each region.",
    alt: "Machine Weft Hairs",
    link: "machine-weft-hairs",
    description: `The definition of the term is already in its name 'RAW' ,meaning the hair is 100% Virgin ie., the pure form of human hair without any chemicals, perming, color treating it or bleaching. It is 100% Pure natural human hair.

    What makes raw hair more special is that it has its cuticles intact and preserved unidirectional throughout the process, which in other words is 100% REMY Human Hair. Since the hair is raw and hasn't any treatment that strips off the cuticles, the hair has its cuticles attached to it, making it convenient to treat the raw hair extension as one's real human hair.
    
    Please don't get confused that raw hair is from a single donor hair. The name 'raw' simply means that the hair is not treated/altered in any way. However, it could have been collected from a single donor or multi-donor. Hair extensions are available with single donor raw hair or raw hair. Single donor hair is the best. So, while buying your hair extension, don't forget to ask or read the product description to find out whether the hair is single donor hair or multi-donor. Our hair extensions are fully made using single donor hair.`,
    value: "machine-weft-hairs",
    to: "/categories/machine-weft-hairs",
  },
  {
    title: "Frontals & Closures",
    image: bulkImage,
    about:
      "You cannot find a single person who said she would cut her hair for personal profit in India like other countries.Its purely religious. Hence we call its ethically sourced hair from temples.",
    alt: "Frontals & Closures",
    link: "frontals-closures",
    description: `The definition of the term is already in its name 'RAW' ,meaning the hair is 100% Virgin ie., the pure form of human hair without any chemicals, perming, color treating it or bleaching. It is 100% Pure natural human hair.

    What makes raw hair more special is that it has its cuticles intact and preserved unidirectional throughout the process, which in other words is 100% REMY Human Hair. Since the hair is raw and hasn't any treatment that strips off the cuticles, the hair has its cuticles attached to it, making it convenient to treat the raw hair extension as one's real human hair.
    
    Please don't get confused that raw hair is from a single donor hair. The name 'raw' simply means that the hair is not treated/altered in any way. However, it could have been collected from a single donor or multi-donor. Hair extensions are available with single donor raw hair or raw hair. Single donor hair is the best. So, while buying your hair extension, don't forget to ask or read the product description to find out whether the hair is single donor hair or multi-donor. Our hair extensions are fully made using single donor hair.`,
    value: "closures",
    to: "/categories/frontals-closures",
  },
  {
    title: "Clip-Ins",
    image: clipinImage,
    about:
      "A quick and easy solution for achieving length and volume. Clip-ins are a classic non-damaging solution for taking your hair to the next level in just a few easy steps.",
    alt: "Clip Ins",
    link: "clip-ins",
    description: `Donating hair to the god is a practice being followed by Indian Hindus for a very long period. The reason for such donations varies from person to person. Some donate hair to say thanks to the Lord for what they have got and some donate out of love and devotion towards the Lord (God).
    
    The Indian hair that is donated is collected by the temples.Temples hold private auctions to sell the hair to vendors. They use the profits to fund community projects like housing, education and health care. We purchase those hair, deep sanitize it and make them into a 100% Raw Indian hair extension as pure and natural as it is, without processing it.`,
    value: "clipins",
    to: "/categories/clip-ins",
  },
  {
    title: "Wigs",
    image: closureImage,
    about:
      "A wig is a head covering made from human hair, animal hair, or synthetic fiber. The word wig is short for periwig and is first recorded in the English language around 1675.",
    alt: "Wigs",
    link: "wigs",
    description: `Donating hair to the god is a practice being followed by Indian Hindus for a very long period. The reason for such donations varies from person to person. Some donate hair to say thanks to the Lord for what they have got and some donate out of love and devotion towards the Lord (God).
    
    The Indian hair that is donated is collected by the temples.Temples hold private auctions to sell the hair to vendors. They use the profits to fund community projects like housing, education and health care. We purchase those hair, deep sanitize it and make them into a 100% Raw Indian hair extension as pure and natural as it is, without processing it.`,
    value: "raw",
    to: "/categories/wigs",
  },
  {
    title: "All Products",
    image: bulkImage,
    about:
      "A quick and easy solution for achieving length and volume. Clip-ins are a classic non-damaging solution for taking your hair to the next level in just a few easy steps.",
    alt: "All Products",
    link: "all-products",
    description: `Donating hair to the god is a practice being followed by Indian Hindus for a very long period. The reason for such donations varies from person to person. Some donate hair to say thanks to the Lord for what they have got and some donate out of love and devotion towards the Lord (God).

    The Indian hair that is donated is collected by the temples.Temples hold private auctions to sell the hair to vendors. They use the profits to fund community projects like housing, education and health care. We purchase those hair, deep sanitize it and make them into a 100% Raw Indian hair extension as pure and natural as it is, without processing it.`,
    value: "all",
    to: "/categories/all-products",
  }
];

export const items = [
  {
    title: "100% UNPROCESSED",
    image:
      "https://i.ndtvimg.com/i/2017-06/hair-care-hair-loss-long_696x400_81497862227.jpg",
    description:
      "Raw Indian Hair Extensions, for those who derserve a high-quality natural hair experience.",
  },
  {
    title: "REMYVIRGIN HAIRS",
    image:
      "https://www.houseofwellness.com.au/wp-content/uploads/2019/02/HOW-hair-care-tips-MAIN.jpg",
    description:
      "The Indian hair that is donated is collected by the temples.",
  },
  {
    title: "CLIP-INS",
    image: "https://www.rmhaircare.eu/images/panoramicas/5/p_sliderfoto1.jpg",
    description:
      "A quick and easy solution for achieving length and volume. Clip-ins are a classic non-damaging solution for taking your hair to the next level in just a few easy steps.",
  },
];

export const testimonials = [
  {
    avatar:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
    name: "Sandra Gael",
    statement:
      "Communication and shipping were great! Fast and easy all the time. I don't know how you awake late night for me. I can't say great things enough about you and your hair! Love from all my clients.",
  },
  {
    avatar:
      "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=699&q=80",
    name: "Kraig McKay",
    statement:
      "I finally found a vendor that sells pure real virgin indian hair. After researching several companies, I took my chance with this one and it was perfection ever since. RemyVirgin was affordable, quick and reliable. Their customer service via whatsapp was awesome and I am a happy and loyal customer forever. Thank you guys so much!!!!",
  },
  {
    avatar: cus3Image,
    name: "Sabrina Hladynets",
    statement:
      "Superior Raw hair is easy to maintain and doesn't tangle at all. I had the confidence to wear the raw curly hair. I would highly recommend your raw indian hair bundles. Super!",
  },
];