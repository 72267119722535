import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { Buffer } from "buffer";

// STYLES
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
// STYLES

import Login from "../pages/Authentication/Login";
import Signup from "../pages/Authentication/Signup";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import ResetPage from "../pages/Authentication/Reset/ResetPage";
import ResetSuccess from "../pages/Authentication/Reset/ResetSuccess";
import ResetFailed from "../pages/Authentication/Reset/ResetFailed";

import "./App.css";
import Header from "../pages/Header/Header";
import ScrollToTop from "../helpers/ScrollToTop";
import Footer from "../pages/Footer/Footer";
import Currency from "../components/molecules/Currency";
import Loader from "../components/molecules/Loader/Loader";
import About from "../pages/Misc/About";
import Contact from "../pages/Misc/Contact";
import Faq from "../pages/Misc/Faq";
import Policy from "../pages/Misc/Policy";
import Privacy from "../pages/Misc/Privacy";
import Terms from "../pages/Misc/Terms";
import { categories, items, testimonials } from "./data";

// LAZY
const NotFound = lazy(() => import("../pages/NotFound/NotFound"));
const Landing = lazy(() => import("../pages/Landing/Landing"));
const Account = lazy(() => import("../pages/Account/Account"));
const Order = lazy(() => import("../pages/Order/Order"));
const Cart = lazy(() => import("../pages/Cart/Cart"));
const Wishlist = lazy(() => import("../pages/Wishlist/Wishlist"));
const Category = lazy(() => import("../pages/CategoryPages/Category"));
const Sale = lazy(() => import("../pages/CategoryPages/Sale"));
const ItemPage = lazy(() => import("../pages/Cart/ItemPage"));
const Checkout = lazy(() => import("../pages/Cart/Checkout"));
const Payment = lazy(() => import("../pages/Cart/Payment"));

const toImageURL = (buffer) => {
  return new Buffer.from(buffer.data).toString("base64");
};
// LAZY
function App() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const settings = useSelector((state) => state.setting);

  const categoriesLoaded = settings.categories.length > 0 ? settings.categories.map((category) => {
    return {
      ...category,
      image: `data:image/png;base64,${toImageURL(category.image)}`,
    };
  }) : categories;

  return (
    <div className="container">
      <ScrollToTop />
      <Currency />
      <Header categories={categoriesLoaded} />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={
            <Landing 
              categories={ categoriesLoaded }
              testimonials={ testimonials }
              items={ items }
            />
          } />
          <Route
            path="/payment"
            element={
              isLoggedIn ? <Payment /> : <Navigate replace to="/login" />
            }
          />
          <Route
            path="/login"
            element={!isLoggedIn ? <Login /> : <Navigate replace to="/" />}
          />
          <Route
            path="/register"
            element={!isLoggedIn ? <Signup /> : <Navigate replace to="/" />}
          />
          <Route
            path="/forgot-password"
            element={
              !isLoggedIn ? <ForgotPassword /> : <Navigate replace to="/" />
            }
          />
          <Route
            path="/resetpage"
            element={!isLoggedIn ? <ResetPage /> : <Navigate replace to="/" />}
          />
          <Route
            path="/reset-password/:token"
            element={
              !isLoggedIn ? <ResetPassword /> : <Navigate replace to="/" />
            }
          />
          <Route
            path="/reset-success"
            element={
              !isLoggedIn ? <ResetSuccess /> : <Navigate replace to="/" />
            }
          />
          <Route
            path="/reset-failed"
            element={
              !isLoggedIn ? <ResetFailed /> : <Navigate replace to="/" />
            }
          />
          {/* PAGES */}
          <Route path="/pages/about" element={<About />} />
          <Route path="/pages/contact" element={<Contact />} />
          <Route path="/pages/faqs" element={<Faq />} />
          <Route path="/pages/policy" element={<Policy />} />
          <Route path="/pages/privacy" element={<Privacy />} />
          <Route path="/pages/terms" element={<Terms />} />
          {/* PAGES */}

          {/* CATEGROIES */}
          <Route
            path="/categories/*"
            element={<Category categories={categoriesLoaded} />}
          />
          <Route path="/sale" element={<Sale />} />
          {/* CATEGROIES */}

          {/* WISHLIST iF LOGGED IN */}
          <Route
            path="/user/wishlist"
            element={isLoggedIn ? <Wishlist /> : <Login />}
          />
          {/* WISHLIST iF LOGGED IN */}

          {/* ORDERS PAGES */}
          <Route
            path="/user/account"
            element={isLoggedIn ? <Account /> : <Login />}
          />
          <Route
            path="/user/account/orders/:id"
            element={isLoggedIn ? <Order /> : <Login />}
          />
          {/* ORDERS PAGES */}

          {/* PRODUCTS */}
          <Route path="/products/:id" element={<ItemPage />} />
          {/* PRODUCTS */}

          {/* CART */}
          <Route path="/cart" element={<Cart />} />
          <Route path="/cart/checkout" element={<Checkout />} />
          {/* CART */}

          {/* NOT FOUND */}
          <Route path="*" element={<NotFound />} />
          {/* NOT FOUND */}
        </Routes>
      </Suspense>
      <Footer
        categories={categoriesLoaded}
      />
    </div>
  );
}

export default App;
