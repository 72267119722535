const ITEM_BASE_URL = "/api/v1/item";

const ITEM_ENDPOINTS = {
  GET_ITEMS: ITEM_BASE_URL + "/getItems", // gives back the items array in data
  GET_ITEM: (itemId) => ITEM_BASE_URL + `/getItem/${itemId}`, // gives back the item in data
  GET_ITEM_IMAGE: (itemId) => ITEM_BASE_URL + `/getItemImage/${itemId}`, // gives back the item image in data 
  GET_ITEMS_BY_LIMIT: (limit) => ITEM_BASE_URL + `/getItemsByLimit/${limit}`, // gives back the items array in data
  GET_ITEMS_BY_CATEGORY: (categoryId, page) =>
    ITEM_BASE_URL + `/getItemsByCategory/${categoryId}/${page}}`, // gives back the items array in data
  GET_ITEMS_BY_PAGINATION: (page) =>
    ITEM_BASE_URL + `/getItemsByPagination/${page}`, // gives back the items array in data
};

export default ITEM_ENDPOINTS;