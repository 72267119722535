import React from "react";
import promoImage from "../../../assets/images/promo.jpeg";
import Button from "../../atoms/Button";
import Link from "../../atoms/Link";
import ImageBanner from "../../molecules/ImageBanner";
import { H2 } from "../../typography/Headings";
import { Description } from "../../typography/Paragraphs";

const Promo = () => {
  return (
    <ImageBanner image={promoImage} padding="2rem 1rem">
      <H2 white>INDIA. THE MULTICULTURAL, MULTI-TEXTURAL COUNTRY.</H2>
      <Description textAlign="justify" margin="2rem 0" white>
        Our Raw Indian Hair offers multiple textures to achieve a seamless,
        natural look with existing styles or go bold with a daring new look.
        With such inherent diversity, Raw Indian Hair doesn’t need to undergo
        chemical treatment or processing to offer an array of head-turning
        styles.
      </Description>
      <Link underline="none" to="/sale">
        <Button padding=".6rem 0" width="100%" white>
          Grab your Deal
        </Button>
      </Link>
    </ImageBanner>
  );
};

export default Promo;
