import { getOrders, getOrder } from "../../controllers/order-controller";
import { orderActions } from "../reducers/order-reducer";

export const loadOrders = () => async (dispatch) => {
  dispatch(orderActions.LOAD_ORDERS_PENDING());
  try {
    const resData = await getOrders();
    dispatch(orderActions.LOAD_ORDERS_SUCCESS({ orders: resData.orders }));
    return resData;
  } catch (error) {
    dispatch(orderActions.LOAD_ORDERS_FAILURE({ error: error.message }));
    throw error;
  }
};

export const getOrderById = (id) => async (dispatch) => {
  try {
    const res = await getOrder(id);
    return res;
  } catch (error) {
    throw error;
  }
};

export const clearOrdersList = () => (dispatch) =>
  dispatch(orderActions.CLEAR_ORDERS_LIST());
