import React from "react";
import styled from "styled-components";
import FlexContainer from "../templates/FlexContainer";
import { H2 } from "../typography/Headings";
import { Description } from "../typography/Paragraphs";

const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.7)
    ),
    url(${(props) => props.image});
  padding: ${(props) => (props.padding ? props.padding : "0")};
`;

const ImageBanner = (props) => {
  const { image, title, description, children, padding, width, mobileWidth } =
    props;
  return (
    <BannerContainer padding={padding} image={image}>
      <FlexContainer
        direction="column"
        width={width || "45rem"}
        mobileWidth={mobileWidth || "100%"}
        self="center"
      >
        {!children && (
          <>
            <H2 white>"{title}"</H2>
            <Description white>{description}</Description>
          </>
        )}
        {children}
      </FlexContainer>
    </BannerContainer>
  );
};

export default ImageBanner;
