import { createSlice } from "@reduxjs/toolkit";

const settingSlice = createSlice({
  name: "setting",
  initialState: {
    loading: false,
    error: null,
    categories: [],
    testimonials: [],
    items: [],
  },
  reducers: {
    LOAD_SETTINGS_PENDING: (state) => {
      state.loading = true;
      state.error = null;
    },
    LOAD_SETTINGS_SUCCESS: (state, action) => {
      state.categories = action.payload.categories;
      state.testimonials = action.payload.testimonials;
      state.items = action.payload.items;
      state.loading = false;
      state.error = null;
    },
    LOAD_SETTINGS_FAILURE: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export const settingActions = settingSlice.actions;
export default settingSlice;
