import React from "react";
import Wrapper from "../../components/templates/Wrapper";
import { LoginForm } from "../../components/molecules/LoginForm/LoginForm";
import Link from "../../components/atoms/Link";
import Label from "../../components/atoms/Label";

const Login = (props) => {
  return (
    <Wrapper
      direction="column"
      margin="2.5rem 0"
      align="center"
      justify="center"
    >
      <LoginForm />
      <Link
        fontSize="1rem"
        color="var(--color-5)"
        margin="1rem 0"
        to="/forgot-password"
      >
        Forgot Password ?
      </Link>
      <Label align="center" color="var(--color-4)">
        Do not have an account
        <Link
          fontSize="1rem"
          color="var(--color-5)"
          margin="1rem 0 1rem .5rem"
          to="/register"
        >
          Create New Account?
        </Link>
      </Label>
    </Wrapper>
  );
};

export default Login;
