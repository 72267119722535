import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 2.5rem;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "bold")};
  color: ${(props) =>
    props.white
      ? "var(--primary-white-color)"
      : props.color
      ? props.color
      : "var(--primary-text-color)"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: 0;
  text-align: ${(props) => (props.align ? props.align : "center")};

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;
export const H2 = styled.h2`
  font-size: 2rem;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "bold")};
  color: ${(props) =>
    props.white
      ? "var(--primary-white-color)"
      : props.color
      ? props.color
      : "var(--primary-text-color)"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: 0;
  text-align: ${(props) => (props.align ? props.align : "center")};

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const H3 = styled.h3`
  font-size: 1.5rem;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "bold")};
  color: ${(props) =>
    props.white
      ? "var(--primary-white-color)"
      : props.color
      ? props.color
      : "var(--primary-text-color)"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: 0;
  text-align: ${(props) => (props.align ? props.align : "center")};

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;
export const H4 = styled.h4`
  font-size: 1.25rem;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "bold")};
  color: ${(props) =>
    props.white
      ? "var(--primary-white-color)"
      : props.color
      ? props.color
      : "var(--primary-text-color)"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: 0;
  text-align: ${(props) => (props.align ? props.align : "center")};

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const H5 = styled.h5`
  font-size: 1rem;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "bold")};
  color: ${(props) =>
    props.white
      ? "var(--primary-white-color)"
      : props.color
      ? props.color
      : "var(--primary-text-color)"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: 0;
  text-align: ${(props) => (props.align ? props.align : "center")};

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const H6 = styled.h6`
  font-size: 0.875rem;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "bold")};
  color: ${(props) =>
    props.white
      ? "var(--primary-white-color)"
      : props.color
      ? props.color
      : "var(--primary-text-color)"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: 0;
  text-align: ${(props) => (props.align ? props.align : "center")};

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;
