import React, { Fragment } from "react";
import FlexContainer from "../../components/templates/FlexContainer";
import { H3, H4, H5, H6 } from "../../components/typography/Headings";
import { Paragraph } from "../../components/typography/Paragraphs";
import Link from "../../components/atoms/Link";
import Label from "../../components/atoms/Label";

import styles from "./Misc.module.css";

const Policy = () => {
  return (
    <Fragment>
      <H3 fontWeight="400" margin=".5rem 0" underlined>
        Return &amp; Exchange Policy
      </H3>
      <FlexContainer
        direction="column"
        margin="0 0 1rem 0"
        self="center"
        maxWidth="50%"
        mobileMargin="0 0 1rem 0"
        mobilePadding="0 0.5rem"
      >
        <H5 color="var(--color-5)" margin="1.5rem 0" align="left">
          PLEASE THROUGHLY READ ALL OF OUR RETURN/EXCHANGE INFORMATION PRIOR TO
          PURCHASE. IF YOU HAVE QUESTIONS PLEASE CONTACT{" "}
          <Link
            fontWeight="500"
            color="var(--primary-color)"
            to="/pages/contact"
          >
            CUSTOMER SERVICE
          </Link>
          , PRIOR TO PURCHASING.
        </H5>
        <H4 margin="0 0 .5rem 0">NOT LIP SERVICE. CLIENT SERVICE</H4>
        <Paragraph padding="0 .5rem">
          RemyVirgin Hair takes pride in on our customer service and high rate
          of customer satisfaction. We thoroughly inspect all hair orders double
          checked in house before leaving our factory in India in order to
          ensure consistency and quality. We are only company that produces hair
          extensions at the source. If for some reason you are not satisfied
          with your order or purchase, we do offer the flexibility of an
          exchange only,{" "}
          <strong>
            not including applicable shipping costs, custom duties and taxes.
          </strong>
        </Paragraph>
        <Paragraph margin=".5rem 0" padding="0 .5rem">
          <strong>Very, Very Important:</strong> Before using your new hair
          extensions, please inspect the product thoroughly to ensure the
          correct length, Color(which is Naturally Virgin Tone), Texture, and
          quality are as desired. We encourage you thoroughly inspect and
          examine the hair, without cutting the zip ties &amp; security seal or
          opening the bundles to ensure satisfaction with the product. We also
          recommend combing through the hair with a loop brush to inspect for
          shedding or tangling for your own piece of mind, while the hair
          secured in the zip ties. After quality satisfaction, treating the hair
          with quality products is essential prior to and after installation.
          Get a second opinion from your hair stylist if you are unsure or have
          doubts. Once the hair is tempered, used we are limited on what we can
          do to help you if a problem arises. <br />
          If you would like to exchange* your product, you must submit a{" "}
          <strong>RMA (Return Merchandise Authorization)</strong> request within
          7 days of receipt of merchandise by emailing{" "}
          <a className={styles.mailto} href="mailto:info@remyvirginhairs.com">
            info@remyvirginhairs.com
          </a>{" "}
          or calling customer service at{" "}
          <a className={styles.mailto} href="tel:+919079923506">
            +91-(9079) 923 506
          </a>{" "}
          M-F 10am - 6pm.
        </Paragraph>
        <Paragraph margin="0 0 .5rem 0" padding="0 .5rem">
          We're here. Whether you've a question before or after you've ordered
          your hair extensions, don't hesitate to reach out. We are here to help
          you 7 days a week and respond within 24 hours.
          <br />
          We are pleased to accommodate replacement and exchanges within 7 days
          on all extensions that have not been opened, worn, washed or tampered
          with. If it isn't, please e-mail us at{" "}
          <a className={styles.mailto} href="mailto:info@remyvirginhairs.com">
            info@remyvirginhairs.com
          </a>{" "}
          to initiate your exchange and we will be more than happy to assist you
          further.
        </Paragraph>
        <H6 color="var(--color-5)" align="left">
          * PRODUCTS PURCHASED DURING SALE, CUSTOM ORDERED ITEMS AND CLOSURES
          AND FRONTALS, 360 FRONTALS, LACE WIGS, CLIP-INS, LACE WIGS, BULK HAIR,
          WEAVES ARE NOT REFUNDABLE OR NON EXCHANGEABLE. SUCH REQUEST WILL NOT
          BE ENTERTAINED INCLUDING THE PAYMENT DISPUTES FOR SUCH ITEMS.
        </H6>
        <H6 color="var(--color-5)" margin="0.5rem 0" align="left">
          <em>
            Please note: Federal law & The Government of India states you cannot
            return human hair products that have been used. We adhere to strict
            policies in regard to federal law, health regulations, and hygiene
            concerns.
            <br />
            We take matters of hygiene and public safety seriously and do not
            allow opened items to be returned for the safety of all our
            customers. We are not alone - other hair extensions suppliers do not
            allow returns of opened items for the same reasons. If the item is
            removed from the bundle, zip ties and opened, worn, washed or
            tampered in any visible manner, or manipulated with heat or
            chemicals of any sort all exchange policies are voided. We are not
            responsible for any damage to your hair or to your hair care
            products.
          </em>
        </H6>
        <Paragraph margin="0 0 .5rem 0" padding="0 .5rem">
          For more questions regarding our{" "}
          <strong>return/exchange policy</strong>, please contact us at{" "}
          <a className={styles.mailto} href="mailto:info@remyvirginhairs.com">
            info@remyvirginhairs.com
          </a>{" "}
          or call us at{" "}
          <a className={styles.mailto} href="tel:+919079923506">
            +91-(9079) 923 506
          </a>{" "}
          M-F 10am - 6pm.
        </Paragraph>
        <Label>Thankyou For choosing us.</Label>
        <Label>
          Have a{" "}
          <Link fontSize="1rem" color="var(--primary-color)" to="/">
            Happy Shopping
          </Link>
        </Label>
      </FlexContainer>
    </Fragment>
  );
};

export default Policy;
