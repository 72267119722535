import { cartActions } from "../reducers/cart-reducer";
import {
  addItemToUserCart,
  getUserCart,
  removeItemFromUserCart,
  updateUserCart,
  checkoutUserCart,
  getClientSecret,
} from "../../controllers/cart-controller";
import { loadOrders } from "./order-actions";

export const getCartItems = (setIsLoading) => async (dispatch) => {
  setIsLoading(true);
  dispatch(cartActions.LOAD_CART_PENDING());
  try {
    const resData = await getUserCart();
    if (!resData.success) throw Error("Could not fetch user cart");
    const { cart } = resData;
    const cartSize = cart.items.reduce((ac, item) => ac + item.quantity, 0);
    dispatch(
      cartActions.LOAD_CART_SUCCESS({
        items: cart.items,
        total: cart.total,
        address: cart.address,
        cartSize: cartSize,
      })
    );
  } catch (error) {
    dispatch(cartActions.LOAD_CART_FAILURE({ error: error.message }));
  }
  setIsLoading(false);
};

export const addItemToCart =
  (body, showToast, navigate) => async (dispatch) => {
    try {
      const resData = await addItemToUserCart(body);
      if (!resData.success) throw Error("Could not add to cart");
      const { cart } = resData;
      const cartSize = cart.items.reduce((ac, item) => ac + item.quantity, 0);
      dispatch(
        cartActions.LOAD_CART_SUCCESS({
          items: cart.items,
          total: cart.total,
          address: cart.address,
          cartSize: cartSize,
        })
      );
      showToast("Added to Cart", "info");
      if (navigate) navigate("/cart");
    } catch (error) {
      dispatch(cartActions.LOAD_CART_FAILURE({ error: error.message }));
      showToast(error.message, "error");
    }
  };

export const updateCart =
  (itemId, quantity, price, size) => async (dispatch) => {
    try {
      const resData = await updateUserCart({ itemId, quantity, price, size });
      const { cart } = resData;
      const cartSize = cart.items.reduce((ac, item) => ac + item.quantity, 0);
      dispatch(
        cartActions.LOAD_CART_SUCCESS({
          items: cart.items,
          total: cart.total,
          address: cart.address,
          cartSize: cartSize,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

export const removeItemFromCart = (itemId) => async (dispatch) => {
  try {
    const resData = await removeItemFromUserCart({ itemId });
    const cart = resData.cart;
    const cartSize = cart.items.reduce((ac, item) => ac + item.quantity, 0);
    dispatch(
      cartActions.LOAD_CART_SUCCESS({
        items: cart.items,
        total: cart.total,
        address: cart.address,
        cartSize: cartSize,
      })
    );
  } catch (error) {
    console.log(error);
  }
};

export const placeOrder = (body, showToast, navigate) => async (dispatch) => {
  try {
    const resData = await checkoutUserCart(body);
    if (!resData.success) throw Error("Order could not be placed.");
    dispatch(cartActions.CLEAR_CART());
    dispatch(loadOrders());
    showToast("Order Placed", "info");
    sessionStorage.removeItem("clientSecret");
    if (navigate) navigate("/user/account/orders/" + resData.order._id);
  } catch (error) {
    showToast(error.message, "error");
  }
};

export const fetchClientSecret = (body) => async (dispatch) => {
  try {
    const resData = await getClientSecret(body);
    if (!resData.success) throw Error(resData.error);
    sessionStorage.setItem("clientSecret", resData.client_secret);
  } catch (error) {
    console.log(error);
  }
};
export const clearCart = () => (dispatch) => dispatch(cartActions.CLEAR_CART());
