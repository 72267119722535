import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { isUserAuthenticated } from "../store/actions/user-actions";
import { loadItems } from "../store/actions/item-actions";
import { Toast } from "primereact/toast";
import { loadSettings } from "../store/actions/setting-actions";

const DataContext = React.createContext({
  showMessageToast: () => {},
  currencySymbol: "",
  exchangeRate: {},
});
let fetched = false;

export const DataContextProvider = (props) => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.user.currency);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [exchangeRate, setExchangeRate] = useState(null);
  const messageToastRef = useRef(null);

  const getCurrencySymbol = useCallback(() => {
    const currencies = [
      { name: "usd", value: "$" },
      { name: "eur", value: "€" },
      { name: "gbp", value: "£" },
      { name: "inr", value: "₹" },
    ];
    const found = currencies.find((c) => c.name === currency);
    if (found){
      setCurrencySymbol(found.value);
    } else {
      setCurrencySymbol("$");
    }
  }, [currency]);

  useEffect(() => {
    getCurrencySymbol();
  }, [getCurrencySymbol]);

  const showMessageToast = (message, type, closeable = true) => {
    messageToastRef.current.show({
      severity: type,
      summary: type,
      detail: message,
      life: 3000,
      closeable,
    });
  };
  useEffect(() => {
    fetch(
      "https://openexchangerates.org/api/latest.json?app_id=171ab36bc6d842deae8407021119664f"
    )
      .then((res) => res.json())
      .then((data) => {
        setExchangeRate(data.rates);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (fetched) return;
    dispatch(loadSettings(showMessageToast));
    dispatch(loadItems(showMessageToast));
    dispatch(isUserAuthenticated);
    fetched = true;
  }, [dispatch]);

  const contentValues = {
    showMessageToast,
    currencySymbol: currencySymbol,
    exchangeRate: exchangeRate,
  };

  return (
    <Fragment>
      <Toast position="bottom-left" ref={messageToastRef} />
      <DataContext.Provider value={contentValues}>
        {props.children}
      </DataContext.Provider>
    </Fragment>
  );
};

export default DataContext;
