import styled from "styled-components";

export const Paragraph = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  color: ${(props) =>
    props.white
      ? "var(--primary-white-color)"
      : props.color
      ? props.color
      : "var(--primary-text-color)"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "justify")};
  line-height: 1.5;
  white-space: pre-wrap;
`;

export const Description = styled.p`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  color: ${(props) =>
    props.white
      ? "var(--primary-white-color)"
      : props.color
      ? props.color
      : "var(--primary-text-color)"};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  line-height: 1.5;
  white-space: pre-wrap;
`;
