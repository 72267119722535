import React, { useState } from "react";
import Button from "../../atoms/Button";
import FlexContainer from "../../templates/FlexContainer";
import styles from "../Molecules.module.css";

const Subscribe = (props) => {
  const { onSubmit } = props;
  const [email, setEmail] = useState("");
  const [disabledBtn, setDisabled] = useState(true);
  const inputChangeHandler = (e) => {
    setEmail(e.target.value);
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  const handleForm = () => {
    setEmail("");
    setDisabled(true);
    onSubmit(email);
  };
  return (
    <FlexContainer
      width="100%"
      justify="flex-start"
      mobileMargin="1rem 0"
      mobileWidth="100%"
      mobileAlign="flex-end"
      breakpoint="450px"
    >
      <input
        className={styles.subscribe_input}
        placeholder="Enter e-mail"
        value={email}
        onChange={inputChangeHandler}
        type="email"
      />
      <Button disabled={disabledBtn} onClick={handleForm} shadow={false} black>
        {" "}
        Subscribe
      </Button>
    </FlexContainer>
  );
};

export default Subscribe;
