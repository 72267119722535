import styled from "styled-components";

const Wrapper = styled.div`
  ${(props) => (props.hide ? `display: none;` : `display: flex`)};
  flex-direction: ${(props) => props.direction || "row"};
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  justify-content: ${(props) => props.justify || "space-between"};
  align-items: ${(props) => props.align || "strech"};
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  gap: ${(props) => props.gap || "0"};

  @media (max-width: 768px) {
    ${(props) => (props.mobileHide ? `display: none;` : `display: flex`)};
    flex-direction: ${(props) => props.mobileDirection || "column"};
    flex-wrap: ${(props) => props.mobileWrap || "wrap"};
    justify-content: ${(props) => props.mobileJustify || "space-between"};
    align-items: ${(props) => props.mobileAlign || "strech"};
    margin: ${(props) => props.mobileMargin || "2rem 0"};
    padding: ${(props) => props.mobilePadding || "0"};
  }
`;

export default Wrapper;
