import { NavLink as NavLinks } from "react-router-dom";
import styled from "styled-components";

const NavLink = styled(NavLinks)`
  &:link,
  &:visited {
    text-align: center;
    text-decoration: none;
    font-size: ${(props) => props.fontSize || "1rem"};
    font-weight: ${(props) => props.fontWeight || "normal"};
    background-color: ${(props) => props.backgroundColor || "none"};
    margin: ${(props) => props.margin || "0"};
    padding: ${(props) => props.padding || "0"};
    min-width: ${(props) => props.minWidth || "0"};
    ${(props) =>
      props.borderRadius && `border-radius: ${props.borderRadius || ".3rem"};`};
    transition: all 0.2s ease-in-out;
    position: relative;
  }

  &:not(:last-child) {
    margin-right: 0;
  }

  &.${(props) => props.activeClassName || "active"} {
    color: var(--primary-white-color) !important;
    border-bottom: 2px solid var(--primary-white-color);
    background-color: var(--navlink-bg-active-color) !important;
  }
`;

export default NavLink;
