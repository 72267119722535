const USER_BASE_URL = "/api/v1/user";

const USER_ENDPOINTS = {
  // EMAIL SERVICE ENDPOINTS
  VERIFY_EMAIL: (token) => USER_BASE_URL + `/verifyEmail/${token}`, // Gives back a success, error and statusCode, message
  RESEND_VERIFICATION_EMAIL: USER_BASE_URL + "/resendVerificationEmail",
  // PASSWORD SERVICE ENDPOINTS
  FORGOT_PASSWORD: USER_BASE_URL + "/forgotPassword", // Gives back a success, error and statusCode, message
  RESET_PASSWORD: (token) => USER_BASE_URL + `/resetPassword/${token}`, // Gives back a success, error and statusCode, message
  // UNAUTHENTICATED USER ENDPOINTS
  REGISTER: USER_BASE_URL + "/register", // Gives back an user, a token ,success, error and statusCode, message
  LOGIN: USER_BASE_URL + "/login", // Gives back an user, a token , and user cart
  // AUTHENTICATED USER ENDPOINTS
  GET_USER: USER_BASE_URL + "/me", // gives back the user
  GET_WISHLIST: USER_BASE_URL + "/getWishlist", // gives back the wishlist
  LOGOUT: USER_BASE_URL + "/logout",
  UPDATE_USER: USER_BASE_URL + "/updateUser", // gives back the updated user
  ADD_NEW_ADDRESS: USER_BASE_URL + "/address/add", // gives back the new address
  DELETE_ADDRESS: USER_BASE_URL + "/address/delete", // deletes the address by its id
  ADD_TO_WISHLIST: USER_BASE_URL + "/wishlist/add", // gives back the new wishlist item
  DELETE_FROM_WISHLIST: USER_BASE_URL + "/wishlist/remove", // gives back the removed wishlist item
  UPDATE_CURRENCY: USER_BASE_URL + "/currency", // gives back the updated currency
};

export default USER_ENDPOINTS;
