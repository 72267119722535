const ORDER_BASE_URL = "/api/v1/order";

const ORDER_ENDPOINTS = {
  GET_ORDERS: ORDER_BASE_URL + "/getUserOrders", // gives back the orders array in data
  GET_ORDER: (orderId) => ORDER_BASE_URL + `/getUserOrder/${orderId}`, // gives back the order
  GET_ORDERS_BY_LIMIT: (limit, page) =>
    ORDER_BASE_URL + `/getUserOrdersByLimit/${limit}/${page}`, // gives back the orders array in data
};

export default ORDER_ENDPOINTS;
