import React from "react";
import Wrapper from "../../components/templates/Wrapper";
import { SignupForm } from "../../components/molecules/SignupForm/SignupForm";
import Link from "../../components/atoms/Link";
import Label from "../../components/atoms/Label";

const Signup = (props) => {
  return (
    <Wrapper
      direction="column"
      margin="2.5rem 0"
      align="center"
      justify="center"
    >
      <SignupForm />
      <Label margin="1rem 0" align="center" color="var(--color-4)">
        Already have an account
        <Link
          fontSize="1rem"
          color="var(--color-5)"
          margin="1rem 0 1rem .5rem"
          to="/login"
        >
          Sign In?
        </Link>
      </Label>
    </Wrapper>
  );
};

export default Signup;
