import React from "react";

import styles from "./Atoms.module.css";

const Button = (props) => {
  const {
    children,
    onClick,
    className,
    disabled,
    primary,
    secondary,
    designer,
    black,
    white,
    margin,
    padding,
    shadow,
    width,
    active,
    type,
    ...rest
  } = props;
  return (
    <button
      type={type}
      className={`
            ${styles.button}
            ${styles.ripple}
            ${className}
            ${primary ? styles.primaryBtn : ""} 
            ${secondary ? styles.secondaryBtn : ""}
            ${black ? styles.blackBtn : ""}
            ${white ? styles.whiteBtn : ""}
            ${disabled ? styles.disabledBtn : ""}
            ${designer ? styles.designerBtn : ""}
            ${active ? styles.activeBtn : ""}
        `}
      style={{
        minWidth: width ? width : "",
        margin: margin ? margin : "0",
        padding: padding ? padding : "auto",
        boxShadow: shadow === false && "none",
      }}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
