import { getCategories } from "../../controllers/setting-controller";
import { settingActions } from "../reducers/setting-reducer";

export const loadSettings = (showMessageToast) => async (dispatch) => {
  dispatch(settingActions.LOAD_SETTINGS_PENDING());
  try {
    const response = await getCategories();
    if (response.success) {
      dispatch(
        settingActions.LOAD_SETTINGS_SUCCESS({
          categories: response.data,
        })
      );
      showMessageToast("Settings loaded", "info");
    } else {
      dispatch(
        settingActions.LOAD_SETTINGS_FAILURE({ error: response.message })
      );
      showMessageToast(response.message, "error");
    }
    return response;
  } catch (error) {
    dispatch(settingActions.LOAD_SETTINGS_FAILURE({ error: error.message }));
    showMessageToast(error.message, "error");
  }
};
