import React, { useRef } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../atoms/Button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";

import styles from "./ResetPasswordForm.module.css";

// Functionalities:
import { resetPassword } from "../../../store/actions/user-actions";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";

export const ResetPasswordForm = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorToastRef = useRef(null);
  const validate = (data) => {
    let errors = {};
    if (!data.password) {
      errors.password = "Password is required.";
    }
    if (!data.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required.";
    }
    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    }

    return errors;
  };

  const onSubmit = (data) => {
    dispatch(resetPassword(data.password, token, navigate));
    // form.reset();
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
    );
  };

  return (
    <div className={styles["form-demo"]}>
      <Toast ref={errorToastRef} />
      <div className="flex justify-content-center">
        <div className={styles["card"]}>
          <Form
            onSubmit={onSubmit}
            initialValues={{ password: "", confirmPassword: "" }}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="p-fluid">
                <Field
                  name="password"
                  render={({ input, meta }) => (
                    <div className={styles["field"]}>
                      <span className="p-float-label">
                        <Password
                          id="password"
                          {...input}
                          toggleMask
                          className={classNames({
                            "p-invalid": isFormFieldValid(meta),
                          })}
                        />
                        <label
                          htmlFor="password"
                          className={classNames({
                            "p-error": isFormFieldValid(meta),
                          })}
                        >
                          Password*
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Field
                  name="confirmPassword"
                  render={({ input, meta }) => (
                    <div className={styles["field"]}>
                      <span className="p-float-label">
                        <Password
                          id="confirmPassword"
                          {...input}
                          toggleMask
                          className={classNames({
                            "p-invalid": isFormFieldValid(meta),
                          })}
                        />
                        <label
                          htmlFor="confirmPassword"
                          className={classNames({
                            "p-error": isFormFieldValid(meta),
                          })}
                        >
                          Confirm Password*
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Button type="submit" width="100%" padding=".6rem 0" secondary>
                  Reset
                </Button>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
};
