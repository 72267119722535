import React from "react";
import Wrapper from "../../../components/templates/Wrapper";
import Link from "../../../components/atoms/Link";
import Label from "../../../components/atoms/Label";

const ResetPage = (props) => {
  return (
    <Wrapper
      direction="column"
      margin="2.5rem 0"
      align="center"
      justify="center"
    >
      <Label fontSize="1rem" align="center" color="var(--color-6)">
        A password reset link has been sent to your email address.
        <Link
          fontSize="1rem"
          color="var(--color-5)"
          margin="1rem 0 1rem .5rem"
          to="/login"
        >
          Back to Login
        </Link>
      </Label>
      <Label fontSize="1rem" align="center" color="var(--color-6)">
        Please check your email and click on the link to reset your password.
      </Label>
      <Label fontSize="1rem" align="center" color="var(--color-6)">
        If you don't see the email in your inbox, please check your spam folder.
        If you still don't see it, please contact us.
      </Label>
    </Wrapper>
  );
};

export default ResetPage;
