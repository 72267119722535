import {
  addNewAddressController,
  deleteAddressController,
  addToWishlistController,
  deleteFromWishlistController,
  getUserController,
  getWishlistController,
  loginUserController,
  registerUserController,
  updateCurrencyController,
  // PASSWORD RESET CONTROLLERS
  forgotPasswordController,
  resetPasswordController,
  // EMAIL VERIFICATION CONTROLLERS
  resendVerificationEmailController,
  verifyEmailController,
} from "../../controllers/user-controller";
import { userActions } from "../reducers/user-reducer";
import { clearOrdersList, loadOrders } from "../actions/order-actions";
import { clearCart } from "./cart-actions";
import CONFIGS from "../../config/config";
import { cartActions } from "../reducers/cart-reducer";

const { TOKEN_KEY } = CONFIGS;

export const registerUser = (user, showToastMessage) => async (dispatch) => {
  dispatch(userActions.SET_USER_PENDING());
  try {
    const resData = await registerUserController(user);
    if (resData.success) {
      localStorage.setItem(TOKEN_KEY, resData.token);
      showToastMessage(resData.message, "success");
      dispatch(userActions.SET_USER_SUCCESS({ user: resData.user }));
    } else {
      showToastMessage(resData.error, "error");
      dispatch(userActions.SET_USER_FAILURE({ error: resData.error }));
    }
  } catch (error) {
    showToastMessage(error.message, "error");
    dispatch(userActions.SET_USER_FAILURE({ error: error.message }));
  }
};

export const loginUser =
  (user, form, showToast, navigate) => async (dispatch) => {
    dispatch(userActions.SET_USER_PENDING());
    try {
      const resData = await loginUserController(user);
      if (!resData.success) throw new Error(resData.error);
      localStorage.setItem(TOKEN_KEY, resData.token);
      const { items } = resData.cart;
      const cartSize = items.reduce((acc, item) => acc + item.quantity, 0);
      showToast(resData.message, "success");
      dispatch(loadOrders());
      dispatch(userActions.SET_USER_SUCCESS({ user: resData.user }));
      dispatch(cartActions.SET_CART_LENGTH({ cartSize: cartSize }));
      form.reset();
      navigate(-1);
    } catch (error) {
      showToast(error.message, "error");
      dispatch(userActions.SET_USER_FAILURE({ error: error.message }));
    }
  };

export const isUserAuthenticated = async (dispatch) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token !== null && token !== undefined) {
    dispatch(userActions.SET_USER_PENDING());
    try {
      const resData = await getUserController();
      dispatch(userActions.SET_USER_SUCCESS({ user: resData.user }));
      dispatch(cartActions.SET_CART_LENGTH({ cartSize: resData.cartSize }));
      dispatch(loadOrders());
    } catch (error) {
      dispatch(userActions.SET_USER_FAILURE({ error }));
    }
  } else {
    dispatch(userActions.LOGOUT_USER());
  }
};

export const logoutUser = (showToast, navigate) => (dispatch) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    showToast("You have been logged out", "success");
    navigate("/login");
  }
  localStorage.removeItem(TOKEN_KEY);
  dispatch(userActions.LOGOUT_USER());
  dispatch(clearOrdersList());
  dispatch(clearCart());
};

export const getWishlist = () => async (dispatch) => {
  try {
    const resData = await getWishlistController();
    return resData;
  } catch (error) {
    console.log(error);
  }
};

export const addToWishlist = (productId, showToast) => async (dispatch) => {
  try {
    const resData = await addToWishlistController({ productId });
    if (!resData.success) throw Error(resData.error.message);
    dispatch(userActions.ADD_TO_WISHLIST({ productId }));
    showToast("Added to wishlist", "info");
  } catch (error) {
    showToast(error.message, "error");
  }
};

export const removeFromWishlist =
  (productId, showToast, removed) => async (dispatch) => {
    try {
      const resData = await deleteFromWishlistController({ productId });
      if (!resData.success) throw Error(resData.error);
      dispatch(userActions.REMOVE_FROM_WISHLIST({ productId }));
      showToast("Removed from wishlist", "info");
      if (removed) removed(false);
    } catch (error) {
      showToast(error.message, "error");
    }
  };

export const changeCurrency =
  (currency, showToast, user) => async (dispatch) => {
    try {
      dispatch(userActions.CHANGE_CURRENCY({ currency }));
      if (user) {
        const resData = await updateCurrencyController({ currency });
        if (!resData.success) throw new Error("Something went wrong.");
      }
    } catch (error) {
      showToast(error.message, "error");
    }
  };

export const addNewAddress = (address, showToast) => async (dispatch) => {
  try {
    const resData = await addNewAddressController({ address });
    if (!resData.success) throw new Error("Something went wrong.");
    dispatch(userActions.ADD_USER_ADDRESS({ addresses: resData.addresses }));
    showToast(resData.message, "success");
  } catch (error) {
    showToast(error.message, "error");
  }
};

export const removeAddress = (addressId, showToast) => async (dispatch) => {
  try {
    const resData = await deleteAddressController({ addressId });
    if (!resData.success) throw new Error("Something went wrong.");
    dispatch(userActions.REMOVE_USER_ADDRESS({ addressId }));
    showToast(resData.message, "success");
  } catch (error) {
    showToast(error.message, "error");
  }
};

export const forgotPassword = (email, showToast, navigate) => async (dispatch) => {
  try {
    const resData = await forgotPasswordController({ email });
    if (!resData.success) throw new Error(resData.error);
    showToast(resData.message, "success");
    navigate("/resetpage");
  } catch (error) {
    showToast(error.message, "error");
  }
};

export const resetPassword = (password, token, navigate) => async (dispatch) => {
  try {
    const resData = await resetPasswordController({password}, token);
    if (!resData.success) throw new Error(resData.error);
    navigate("/reset-success");
  } catch (error) {
    navigate("/reset-failed");
  }
};

export const verifyEmail = (token, navigate) => async (dispatch) => {
  try {
    const resData = await verifyEmailController(token);
    if (!resData.success) throw new Error(resData.error);
    navigate("/email-verified");
  } catch (error) {
    navigate("/email-verification-failed");
  }
};

export const resendVerificationEmail = (email, showToast) => async (dispatch) => {
  try {
    const resData = await resendVerificationEmailController({email});
    if (!resData.success) throw new Error(resData.error);
    showToast(resData.message, "success");
  } catch (error) {
    showToast(error.message, "error");
  }
};
