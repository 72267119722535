import React, { Fragment } from "react";
import FlexContainer from "../../components/templates/FlexContainer";
import { H3, H4 } from "../../components/typography/Headings";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Paragraph } from "../../components/typography/Paragraphs";

const Faq = () => {
  return (
    <Fragment>
      <H3 fontWeight="400" margin=".5rem 0" underlined>
        Help Section
      </H3>
      <FlexContainer
        mobilePadding="0 .5rem"
        direction="column"
        margin="0 0 1rem 0"
        self="center"
        maxWidth="50%"
        mobileMargin="0 0 1rem 0"
      >
        <H4 color="var(--color-5)" margin="1.5rem 0" align="left">
          VIRGIN HAIR EXTENSIONS | RAW INDIAN HAIR EXTENSIONS | REMY INDIAN
          TEMPLE HAIR
        </H4>
        <Accordion multiple activeIndex={[0]}>
          <AccordionTab header="What is Virgin Indian hair?">
            <Paragraph margin="0 0 .5rem 0" fontSize=".8rem" fontWeight="500">
              Virgin Indian Hair is considered to be the finest quality of human
              hair because the cuticles are kept intact and not stripped, like
              in most other Non-Remy or Non-Virgin or Chemically treated hair
              extensions. Preserving the hairs’ cuticles and aligning them in a
              unidirectional fashion creates extensions that are completely
              natural in appearance.
            </Paragraph>
            <Paragraph margin="0 0 .5rem 0" fontSize=".8rem" fontWeight="500">
              Virgin hair will have split ends, and will have a non-uniform curl
              pattern. The color can also slightly vary. To ensure you have
              quality Virgin hair run a strand between your thumb and index
              finger. The hair strand should feel smooth going downwards towards
              the direction of hair growth, however you should feel some
              resistance as you move your fingers in the opposite direction.
              This resistance signifies the cuticle is present and has not been
              stripped.
            </Paragraph>
            <Paragraph fontSize=".8rem" fontWeight="500">
              Any hair that has a perfectly uniform pattern more than likely is
              not virgin hair. Virgin hair is natural, unprocessed hair that
              grows out of an individual’s scalp. Most people do not have a
              perfect wave from root to tip and normally have irregular curls,
              waves or ringlets on their head. The Virgin Hair is also known as
              Raw Indian Hair Extensions. Which is healthy hair directly
              collected from the temples. The Virgin Raw Hair is 100%
              Unprocessed Human Hair.
            </Paragraph>
          </AccordionTab>
          <AccordionTab header="What is the source for the Indian Temple hair or Remy Indian Hair?">
            <Paragraph margin="0 0 .5rem 0" fontSize=".8rem" fontWeight="500">
              Every year lakhs of Indian women specially in southern part of
              India goes to temples like Tirupati Temple, Yadgirigutta Temple,
              Simhachalam Temple and a lot small temples and offer their head to
              the god once their wishes are fulfilled.
            </Paragraph>
            <Paragraph fontSize=".8rem" fontWeight="500">
              The offering of the hair is done by Men and Women both. So the
              temple sell the hair to the hair companies or buyers in bulk.
            </Paragraph>
          </AccordionTab>
          <AccordionTab header="What textures come in the Virgin/Raw Indian hair?">
            <Paragraph margin="0 0 .5rem 0" fontSize=".8rem" fontWeight="500">
              Raw/Virgin Indian Hair comes in three natural textures known as :
            </Paragraph>
            <Paragraph fontSize=".8rem" fontWeight="500">
              Raw Indian Wavy Hair Extensions, Raw Indian Straight Hair
              Extensions, Raw Indian Curly Hair Extensions.
            </Paragraph>
          </AccordionTab>
          <AccordionTab header="What is the life of Raw/Virgin Indian Hair?">
            <Paragraph fontSize=".8rem" fontWeight="500">
              Virgin Indian Hair Extensions also known as Raw Indian Hair
              Extensions last more than 1.5 years or more than that easily.
            </Paragraph>
          </AccordionTab>
          <AccordionTab header="What is the life of Raw/Virgin Indian Hair?">
            <Paragraph fontSize=".8rem" fontWeight="500">
              Raw and Virgin are the same terminology used to identify the
              unprocessed human hair extensions. Raw Indian Hair is 100%
              Chemicaly unprocessed Hair Extensions.
            </Paragraph>
          </AccordionTab>
        </Accordion>
        <H4 color="var(--color-5)" margin="1.5rem 0" align="left">
          SHIPMENT | ORDER &amp; RETURNS | TRACKING
        </H4>
        <Accordion multiple activeIndex={[0]}>
          <AccordionTab header="How to track the shipment?">
            <Paragraph fontSize=".8rem" fontWeight="500">
              You can track the shipment by entering your order number in the
              tracking section.
            </Paragraph>
          </AccordionTab>
        </Accordion>
      </FlexContainer>
    </Fragment>
  );
};

export default Faq;
