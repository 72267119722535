import React, { useContext, useState} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { logoutUser } from '../../store/actions/user-actions';
import DataContext from '../../context/data-context';

import FlexContainer from '../../components/templates/FlexContainer';
import Link from '../../components/atoms/Link';
import logo from '../../assets/images/rlogo.svg';

import styles from './Header.module.css';
import { useNavigate } from 'react-router-dom';
import { H5 } from '../../components/typography/Headings';
import Label from '../../components/atoms/Label';
import NavLink from '../../components/atoms/NavLink';
import { NavLink as Navlink } from 'react-router-dom';

const Header = (props) => {
    const {
        categories = [],
    } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const cartSize = useSelector(state => state.cart.cartSize);
    const [menuOpen, setMenuOpen] = useState(false);
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);
    const dataCtx = useContext(DataContext);
    const showMessageToast = (message, type) => {
        dataCtx.showMessageToast(message, type);
    }
    const toggleMenu = () => setMenuOpen(prevState => !prevState);
    const logoutHandler = () => {
        dispatch(logoutUser(showMessageToast, navigate));
    }
    const imgStyle = {
        width: '60px',
        height: '60px',
        padding: '6px',
        borderRadius: '56px',
        backgroundColor: 'var(--secondary-color)',
    }
    return (
        <FlexContainer mobilePosition='sticky' top='0' zIndex='8' direction='column' padding='1.6rem' mobilePadding='1.4rem' backgroundColor='#e0d2d2'>
            <FlexContainer breakpoint='484px' width='100%' justify='space-between' align='center' mobileDirection='row' mobileJustify='space-between' mobileWidth='100%'>
                <FlexContainer breakpoint='484px' mobile='none'>
                    <div className={styles.search}>
                        <span title='Search' className={styles.icon}>
                            <ion-icon name="search-outline"></ion-icon>
                        </span>
                    </div>
                </FlexContainer>
                <div className={styles.logo}>
                    <Link underline='none' to='/'><img style={imgStyle} src={logo} alt="" /></Link>
                </div>
                <div className={styles.nav_links}>
                    <FlexContainer breakpoint='484px' display='none' mobileMargin='0 1rem 0 0'>
                        <span title='Search' className={styles.icon}>
                            <ion-icon name="search-outline"></ion-icon>
                        </span>
                    </FlexContainer>
                    <FlexContainer breakpoint='768px' display='none' mobileMargin='0 1rem 0 0'>
                        <span title='Menu' onClick={toggleMenu} className={styles.icon}>
                            <ion-icon name="menu-outline"></ion-icon>
                        </span>
                    </FlexContainer>
                    <FlexContainer breakpoint='768px' mobile='none'>
                        <Link title='Cart' to='/cart'>
                            <ion-icon name="bag-handle-outline"></ion-icon>
                            <span className={styles.tag1}>{ cartSize || 0}</span>
                        </Link>
                        {isLoggedIn && <Link title='Wishlist' to='/user/wishlist'>
                                <ion-icon name="heart-outline"></ion-icon>
                                <span className={styles.tag1}>{user && (user.wishlist.length || 0)}</span>
                        </Link>}
                        <Link title={isLoggedIn ? 'Account': 'Login'} to={isLoggedIn ? '/user/account' : '/login'}><ion-icon name="person-outline"></ion-icon></Link>
                        {isLoggedIn &&<Link title='Logout' to='/login' onClick={logoutHandler}><ion-icon name="log-out-outline"></ion-icon></Link>}
                    </FlexContainer>
                </div>
            </FlexContainer>
            <FlexContainer margin='1.8rem 0 0 0' align='center' justify='center' mobile='none'>
                    <nav className={styles.category_links}>
                        {categories.map((link, index) => (
                            <Navlink key={index} style={({isActive}) => ({
                                borderBottom: isActive ? '2px solid var(--primary-text-color)' : '',
                                color: isActive ? 'var(--primary-text-color)' : ''
                            })} to={link.to}>{link.title}</Navlink>
                        ))}
                    </nav>
            </FlexContainer>
            { menuOpen &&
                <FlexContainer mobileZIndex='99' zIndex='99' top='0' mobileJustify='flex-start' mobileWidth='100%' mobileHeight='100vh' display='none' position='fixed' breakpoint='768px' backgroundColor='var(--color-3)'>
                    {isLoggedIn && 
                        <span className={styles.greeting}>
                            <Label>Welcome</Label><H5>{ user && user.name.split(' ')[0] }</H5>
                    </span>}
                    <span title='Close' onClick={toggleMenu} className={styles.close}>
                            <ion-icon name="close-outline"></ion-icon>
                    </span>
                    <nav className={styles.category_links}>
                        <NavLink onClick={toggleMenu} to='/'>Home</NavLink>
                        {categories.map((link, index) => (
                            <NavLink key={index} onClick={toggleMenu} to={link.to}>{link.title}</NavLink>
                        ))}
                        <span style={{border: '1px solid var(--color-4)', width: '100%', margin:'.5rem 0'}}></span>
                        {!isLoggedIn && <NavLink onClick={toggleMenu} to='/login'>Login</NavLink>}
                        {!isLoggedIn && 
                        <NavLink onClick={toggleMenu} title='cart' to='/cart'>
                            <ion-icon name="bag-handle-outline"></ion-icon>
                            <span className={styles.tag2}>{ cartSize || 0}</span>
                        </NavLink>}
                        <div className={styles.logged_nav}>
                            {isLoggedIn && <NavLink onClick={toggleMenu} title='Account' to='/user/account'><ion-icon name="person-outline"></ion-icon></NavLink>}
                            {isLoggedIn && 
                            <NavLink onClick={toggleMenu} title='Wishlist' to='/user/wishlist'>
                                <ion-icon name="heart-outline"></ion-icon>
                                <span className={styles.tag2}>{user && (user.wishlist.length || 0)}</span>
                            </NavLink>}
                            {isLoggedIn && 
                            <NavLink onClick={toggleMenu} title='cart' to='/cart'>
                                <ion-icon name="bag-handle-outline"></ion-icon>
                                <span className={styles.tag2}>{ cartSize || 0}</span>
                            </NavLink>}
                        </div>
                        {isLoggedIn && <Link onClick={() => {toggleMenu();logoutHandler();}} title='logout' to='/'><ion-icon name="log-out-outline"></ion-icon></Link>}
                    </nav>
                </FlexContainer>
             }
        </FlexContainer>
    )
}

export default Header;