import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./reducers/cart-reducer";
import itemSlice from "./reducers/item-reducers";
import orderSlice from "./reducers/order-reducer";
import settingSlice from "./reducers/setting-reducer";
import userSlice from "./reducers/user-reducer";

const store = configureStore({
  reducer: {
    cart: cartSlice.reducer,
    item: itemSlice.reducer,
    order: orderSlice.reducer,
    setting: settingSlice.reducer,
    user: userSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export default store;
