let STRIPE_PK = process.env.REACT_APP_STRIPE_PK_TEST;
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_DEV_PREVIEW !== 'dev_preview') {
    STRIPE_PK = process.env.REACT_APP_STRIPE_PK_LIVE;
}
const CONFIGS = {
    DEV_BASE_URL: 'http://localhost:5000',
    TOKEN_KEY: 'token',
    STRIPE_PK,
}

export default CONFIGS;