const CART_BASE_URL = "/api/v1/cart";

const CART_ENDPOINTS = {
  GET_CART: CART_BASE_URL + "/", // gives back the cart
  ADD_TO_CART: CART_BASE_URL + "/addItem", // gives back the new cart item
  DELETE_FROM_CART: CART_BASE_URL + "/removeItem", // gives back the removed cart item
  GET_CLIENT_SECRET: CART_BASE_URL + "/client-secret", // gives back the client secret
  CHECKOUT: CART_BASE_URL + "/checkout", // gives back the order
  UPDATE_CART: CART_BASE_URL + "/updateItem", // gives back the updated cart item
  ADD_ADDRESS: CART_BASE_URL + "/addAddress", // gives back the new address
};

export default CART_ENDPOINTS;
