import styled from "styled-components";

const FlexContainer = styled.div`
  display: ${(props) => props.display || "flex"};
  flex-direction: ${(props) => props.direction || "row"};
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  justify-content: ${(props) => props.justify || "space-between"};
  align-items: ${(props) => props.align || "strech"};
  align-content: ${(props) => props.alignContent || "stretch"};
  flex-grow: ${(props) => props.grow || "0"};
  flex-shrink: ${(props) => props.shrink || "0"};
  flex-basis: ${(props) => props.basis || "auto"};
  flex: ${(props) => props.flex || "none"};
  width: ${(props) => props.width || "auto"};
  max-width: ${(props) => props.maxWidth || "auto"};
  min-width: ${(props) => props.minWidth || "auto"};
  height: ${(props) => props.height || "auto"};
  padding: ${(props) => props.padding || "0"};
  margin: ${(props) => props.margin || "0"};
  align-self: ${(props) => props.self || "auto"};
  position: ${(props) => props.position || "none"};
  z-index: ${(props) => props.zIndex || "auto"};
  top: ${(props) => props.top || "auto"};
  right: ${(props) => props.right || "auto"};
  bottom: ${(props) => props.bottom || "auto"};
  left: ${(props) => props.left || "auto"};
  gap: ${(props) => props.gap || "0"};
  border-radius: ${(props) => props.borderRadius || "0"};

  color: ${(props) => props.color || "var(--primary-text-color)"};
  background-color: ${(props) => props.backgroundColor || "transparent"};
  box-shadow: ${(props) =>
    props.boxShadow && (props.boxShadow || "0 0 0.5rem 0 rgba(0, 0, 0, 0.2)")};
  ${(props) =>
    props.primary &&
    `
        background-color: var(--primary-background-color);
        color: var(--primary-text-color);
    `};

  @media (max-width: ${(props) => props.breakpoint || "768px"}) {
    display: ${(props) => props.mobile || "flex"};
    flex-direction: ${(props) => props.mobileDirection || "column"};
    flex-wrap: ${(props) => props.mobileWrap || "nowrap"};
    justify-content: ${(props) => props.mobileJustify || "space-between"};
    align-items: ${(props) => props.mobileAlign || "center"};
    align-content: ${(props) => props.mobileAlignContent || "stretch"};
    flex-grow: ${(props) => props.mobileGrow || "0"};
    flex-shrink: ${(props) => props.mobileShrink || "0"};
    flex-basis: ${(props) => props.mobileBasis || "auto"};
    flex: ${(props) => props.mobileFlex || "none"};
    width: ${(props) => props.mobileWidth || "auto"};
    max-width: ${(props) => props.mobileMaxWidth || "100%"};
    min-width: ${(props) => props.mobileMixWidth || ""};
    height: ${(props) => props.mobileHeight || "auto"};
    padding: ${(props) => props.mobilePadding || "0"};
    margin: ${(props) => props.mobileMargin || "0"};
    position: ${(props) => props.mobilePosition || "none"};
    gap: ${(props) => props.mobileGap || "0rem"};
    z-index: ${(props) => props.mobileZIndex || ""};
  }
`;

export default FlexContainer;
