import { getItems } from "../../controllers/item-controller";
import { itemActions } from "../reducers/item-reducers";

export const loadItems = (showToast) => async (dispatch) => {
  dispatch(itemActions.LOAD_ITEMS_PENDING());
  try {
    const resData = await getItems();
    dispatch(itemActions.LOAD_ITEMS_SUCCESS({ items: resData.data }));
    return resData;
  } catch (error) {
    showToast("Something went wrong.", "error");
    dispatch(itemActions.LOAD_ITEMS_FAILURE({ error: error.message }));
  }
};
