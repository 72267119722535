import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    loading: false,
    error: null,
    address: null,
    total: 0,
    cartSize: 0,
    clientSecret: null,
  },
  reducers: {
    LOAD_CART_PENDING: (state) => {
      state.items = [];
      state.loading = true;
      state.error = null;
      state.address = null;
      state.total = 0;
      state.cartSize = 0;
    },
    LOAD_CART_SUCCESS: (state, action) => {
      state.items = action.payload.items || [];
      state.address = action.payload.address || "";
      state.total = action.payload.total || 0;
      state.cartSize = action.payload.cartSize || 0;
      state.loading = false;
      state.error = null;
    },
    LOAD_CART_FAILURE: (state, action) => {
      state.items = [];
      state.loading = false;
      state.error = action.payload.error;
    },
    SET_LOCAL_CART: (state, action) => {
      state.items = [...action.payload.items];
      state.total = action.payload.total;
    },
    CLEAR_CART: (state) => {
      state.items = [];
      state.address = null;
      state.total = 0;
      state.loading = false;
      state.cartSize = 0;
    },
    SET_CART_LENGTH: (state, action) => {
      state.cartSize = action.payload.cartSize;
    },
    SET_CLIENT_SECRET: (state, action) => {
      state.clientSecret = action.payload.clientSecret;
    }
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice;
