import React from "react";
import Wrapper from "../../components/templates/Wrapper";
import {H4} from "../../components/typography/Headings";
import { ResetPasswordForm } from "../../components/molecules/ResetPassword/ResetPasswordForm";

const ResetPassword = (props) => {
  return (
    <Wrapper
      direction="column"
      margin="2.5rem 0"
      align="center"
      justify="center"
    >
      <H4 color="var(--color-5)" fontWeight="500">Reset Password</H4>
      <ResetPasswordForm />
    </Wrapper>
  );
};

export default ResetPassword;
