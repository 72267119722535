import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
    user: null,
    currency: "usd",
    error: null,
    loading: false,
  },
  reducers: {
    SET_USER_PENDING: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.currency = "usd";
      state.error = null;
      state.loading = true;
    },
    SET_USER_SUCCESS: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.currency = action.payload.user.currency;
      state.error = null;
      state.loading = false;
    },
    SET_USER_FAILURE: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.currency = "usd";
      state.error = action.payload.error;
      state.loading = false;
    },
    LOGOUT_USER: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.error = null;
      state.loading = false;
    },
    ADD_TO_WISHLIST: (state, action) => {
      const newList = [
        ...state.user.wishlist,
        { item: action.payload.productId },
      ];
      state.user.wishlist = newList;
    },
    REMOVE_FROM_WISHLIST: (state, action) => {
      const filteredList = state.user.wishlist.filter(
        (item) => item.item !== action.payload.productId
      );
      state.user.wishlist = filteredList;
    },
    CHANGE_CURRENCY: (state, action) => {
      state.currency = action.payload.currency;
    },
    ADD_USER_ADDRESS: (state, action) => {
      state.user.addresses = action.payload.addresses;
    },
    REMOVE_USER_ADDRESS: (state, action) => {
      state.user.addresses = state.user.addresses.filter(
        (address) => address._id !== action.payload.addressId
      );
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;
