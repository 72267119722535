class Interceptor {
  constructor() {
    this.interceptors = [];
  }
  use(fulfilled, rejected) {
    this.interceptors.push([fulfilled, rejected]);
  }
  get() {
    return this.interceptors;
  }
}

const Fetch = (url, init) => {
  const requestInterceptors = Fetch.requestInterceptors.get().reverse();
  const responseInterceptors = Fetch.responseInterceptors.get();
  const dispatchRequest = (init) => fetch(url, init);

  const chain = [
    ...requestInterceptors,
    [dispatchRequest, undefined],
    ...responseInterceptors,
  ];

  let promise = Promise.resolve(init);

  while (chain.length) {
    const [onFulfilled, onRejected] = chain.shift();
    promise = promise.then(onFulfilled, onRejected);
  }

  return promise;
};

Fetch.requestInterceptors = new Interceptor();
Fetch.responseInterceptors = new Interceptor();

export default Fetch;
