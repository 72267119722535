import React, { Fragment } from "react";
import FlexContainer from "../../components/templates/FlexContainer";
import { H3, H4 } from "../../components/typography/Headings";
import { Paragraph } from "../../components/typography/Paragraphs";
import Link from "../../components/atoms/Link";
import Label from "../../components/atoms/Label";

import styles from "./Misc.module.css";

const Contact = () => {
  return (
    <Fragment>
      <H3 fontWeight="400" margin=".5rem 0" underlined>
        Contact Us
      </H3>
      <FlexContainer
        direction="column"
        margin="0 0 1rem 0"
        self="center"
        maxWidth="50%"
        mobileMargin="0 0 1rem 0"
      >
        <H4 color="var(--color-5)" margin="1.5rem 0" align="left">
          NOT LIP SERVICE. CLIENT SERVICE
        </H4>
        <Paragraph padding="0 .5rem">
          We believe in ‘<strong>NOT LIP SERVICE. CLIENT SERVICE</strong>’. We
          offer full customer support throughout the buying process and assign
          all wholesale customers an Account Executive to manage their needs.
          Whether you've a question before or after you've ordered your hair
          extensions, don't hesitate to reach out. We are here to help you 7
          days a week and respond within 24 hours. Plus, you can find most
          answers to your questions right on our{" "}
          <Link color="var(--primary-color)" to="/pages/faqs">
            FAQ
          </Link>{" "}
          page.
        </Paragraph>
        <Paragraph margin=".5rem 0" padding="0 .5rem">
          You can request to join Our official Facebook Group for exclusive
          deals and help : Facebook.com/groups/RemyVirginHairs
          <ul className={styles.list__contact}>
            <li>
              <Label fontWeight="600">
                Email :{" "}
                <a
                  className={styles.mailto}
                  href="mailto:info@remyvirginhairs.com"
                >
                  info@remyvirginhairs.com
                </a>
              </Label>
            </li>
            <li>
              <Label fontWeight="600">
                WhatsApp :
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={styles.mailto}
                  href="https://wa.me/+919079923506"
                >
                  wa.me/+919079923506
                </a>
              </Label>
            </li>
            <li>
              <Label fontWeight="600">
                Call :{" "}
                <a className={styles.mailto} href="tel:+919079923506">
                  +91-(9079) 923 506
                </a>
              </Label>
            </li>
          </ul>
        </Paragraph>
      </FlexContainer>
    </Fragment>
  );
};

export default Contact;
