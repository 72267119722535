import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Field } from "react-final-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import { CountryService } from "../../../services/CountryService";
import Button from "../../atoms/Button";
import styles from "./SignupForm.module.css";

// Functionalities:
import { useDispatch } from "react-redux";
import { registerUser } from "../../../store/actions/user-actions";
import DataContext from "../../../context/data-context";

export const SignupForm = () => {
  const dispatch = useDispatch();
  const dataCtx = useContext(DataContext);
  const toast = useRef(null);
  const [countries, setCountries] = useState([]);
  const countryservice = new CountryService();

  useEffect(() => {
    setCountries(countryservice.getCountries());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showToastMessage = (message, type) => {
    dataCtx.showMessageToast(message, type);
  };

  const validate = (data) => {
    let errors = {};

    if (!data.name) {
      errors.name = "Name is required.";
    }

    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = "Invalid email address. E.g. example@email.com";
    }

    if (!data.password) {
      errors.password = "Password is required.";
    }

    if (!data.phone) {
      errors.contact = "Contact is required.";
    }

    if (!data.accept) {
      errors.accept = "You need to agree to the terms and conditions.";
    }

    return errors;
  };

  const onSubmit = (data, form) => {
    dispatch(registerUser(data, showToastMessage));
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
    );
  };

  const passwordHeader = <h6>Pick a password</h6>;
  const passwordFooter = (
    <React.Fragment>
      <Divider />
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </React.Fragment>
  );

  return (
    <div className={styles["form-demo"]}>
      {/* <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                <div className="flex align-items-center flex-column pt-6 px-3">
                    <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                    <h5>Registration Successful!</h5>
                    <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                        Your account is registered under name <b>{formData.name}</b> ; it'll be valid next 30 days without activation. Please check <b>{formData.email}</b> & <b>
                            {formData.contact}</b> for activation instructions.
                    </p>
                </div>
            </Dialog> */}
      <Toast ref={toast} />
      <div className="flex justify-content-center">
        <div className={styles["card"]}>
          <h5 className={styles["text-center"]}>Register</h5>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              name: "",
              email: "",
              password: "",
              country: null,
              phone: "",
              accept: false,
            }}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="p-fluid">
                <Field
                  name="name"
                  render={({ input, meta }) => (
                    <div className={styles["field"]}>
                      <span className="p-float-label">
                        <InputText
                          id="name"
                          {...input}
                          autoFocus
                          className={classNames({
                            "p-invalid": isFormFieldValid(meta),
                          })}
                        />
                        <label
                          htmlFor="name"
                          className={classNames({
                            "p-error": isFormFieldValid(meta),
                          })}
                        >
                          Name*
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Field
                  name="email"
                  render={({ input, meta }) => (
                    <div className={styles["field"]}>
                      <span className="p-float-label p-input-icon-right">
                        <i className="pi pi-envelope" />
                        <InputText
                          id="email"
                          {...input}
                          className={classNames({
                            "p-invalid": isFormFieldValid(meta),
                          })}
                        />
                        <label
                          htmlFor="email"
                          className={classNames({
                            "p-error": isFormFieldValid(meta),
                          })}
                        >
                          Email*
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Field
                  name="password"
                  render={({ input, meta }) => (
                    <div className={styles["field"]}>
                      <span className="p-float-label">
                        <Password
                          id="password"
                          {...input}
                          toggleMask
                          className={classNames({
                            "p-invalid": isFormFieldValid(meta),
                          })}
                          header={passwordHeader}
                          footer={passwordFooter}
                        />
                        <label
                          htmlFor="password"
                          className={classNames({
                            "p-error": isFormFieldValid(meta),
                          })}
                        >
                          Password*
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                {/* <Field name="date" render={({ input }) => (
                                <div className={styles["field"]}>
                                    <span className="p-float-label">
                                        <Calendar id="date" {...input} dateFormat="dd/mm/yy" mask="99/99/9999" showIcon />
                                        <label htmlFor="date">Birthday</label>
                                    </span>
                                </div>
                            )} /> */}
                <Field
                  name="country"
                  render={({ input }) => (
                    <div className={styles["field"]}>
                      <span className="p-float-label">
                        <Dropdown
                          id="country"
                          {...input}
                          options={countries}
                          optionLabel="name"
                        />
                        <label htmlFor="country">Country</label>
                      </span>
                    </div>
                  )}
                />
                <Field
                  name="phone"
                  render={({ input, meta }) => (
                    <div className={styles["field"]}>
                      <span className="p-float-label">
                        <InputText
                          id="contact"
                          {...input}
                          className={classNames({
                            "p-invalid": isFormFieldValid(meta),
                          })}
                        />
                        <label
                          htmlFor="contact"
                          className={classNames({
                            "p-error": isFormFieldValid(meta),
                          })}
                        >
                          Contact*
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Field
                  name="accept"
                  type="checkbox"
                  render={({ input, meta }) => (
                    <div className={styles["field-checkbox"]}>
                      <Checkbox
                        inputId="accept"
                        {...input}
                        className={classNames({
                          "p-invalid": isFormFieldValid(meta),
                        })}
                      />
                      <label
                        htmlFor="accept"
                        className={classNames({
                          "p-error": isFormFieldValid(meta),
                        })}
                      >
                        I agree to the terms and conditions*
                      </label>
                    </div>
                  )}
                />

                <Button secondary type="submit" width="100%" padding=".6rem 0">
                  Sign up
                </Button>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
};
