import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    loading: false,
    error: null,
    limit: 10,
    page: 1,
  },
  reducers: {
    LOAD_ORDERS_PENDING: (state) => {
      state.orders = [];
      state.loading = true;
      state.error = null;
    },
    LOAD_ORDERS_SUCCESS: (state, action) => {
      state.orders = action.payload.orders;
      state.loading = false;
      state.error = null;
    },
    LOAD_ORDERS_FAILURE: (state, action) => {
      state.orders = [];
      state.loading = false;
      state.error = action.payload.error;
    },
    LOAD_MORE_ORDERS_PENDING: (state) => {
      state.loading = true;
      state.error = null;
    },
    LOAD_MORE_ORDERS_SUCCESS: (state, action) => {
      state.orders = [...state.orders, ...action.payload.items];
      state.loading = false;
      state.error = null;
      state.page += 1;
    },
    LOAD_MORE_ORDERS_FAILURE: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    CLEAR_ORDERS_LIST: (state) => {
      state.orders = [];
      state.loading = false;
      state.error = null;
      state.page = 1;
    },
  },
});

export const orderActions = orderSlice.actions;
export default orderSlice;
