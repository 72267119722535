import styled from "styled-components";

const Label = styled.label`
  color: ${(props) => props.color || "var(--primary-text-color)"};
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  margin: ${(props) => props.margin || ".2rem"};
  letter-spaceing: ${(props) => props.spaceing || "0"};
  font-family: ${(props) => props.family || "auto"};
  text-decoration: ${(props) => props.decoration || "none"};
  text-align: ${(props) => props.align || ""};
`;

export default Label;
