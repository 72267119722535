import { createSlice } from "@reduxjs/toolkit";

const itemSlice = createSlice({
  name: "item",
  initialState: {
    items: [],
    loading: false,
    error: null,
  },
  reducers: {
    LOAD_ITEMS_PENDING: (state) => {
      state.items = [];
      state.loading = true;
      state.error = null;
    },
    LOAD_ITEMS_SUCCESS: (state, action) => {
      state.items = action.payload.items;
      state.loading = false;
      state.error = null;
    },
    LOAD_ITEMS_FAILURE: (state, action) => {
      state.items = [];
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export const itemActions = itemSlice.actions;
export default itemSlice;
