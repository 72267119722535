import React from "react";
import Wrapper from "../../components/templates/Wrapper";
import { ForgotPasswordForm } from "../../components/molecules/ForgotPasswordForm/ForgotPasswordForm";

const ForgotPassword = (props) => {
  return (
    <Wrapper
      direction="column"
      margin="2.5rem 0"
      align="center"
      justify="center"
    >
      <ForgotPasswordForm />
    </Wrapper>
  );
};

export default ForgotPassword;
