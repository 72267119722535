import React, { Fragment, useContext } from "react";
import styled from "styled-components";
import Link from "../../components/atoms/Link";
import logo from "../../assets/images/rlogo.svg";
import logo_name from "../../assets/images/remi.svg";

import FlexContainer from "../../components/templates/FlexContainer";

import Subscribe from "../../components/molecules/Subscribe/Subscribe";
import { Description, Paragraph } from "../../components/typography/Paragraphs";
import { H5 } from "../../components/typography/Headings";
import DataContext from "../../context/data-context";

const FooterLinks = styled(FlexContainer)`
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 60px 0;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const Footer = (props) => {
  const { categories = [] } = props;
  const ctx = useContext(DataContext);
  const handleSubmit = (email) => {
    ctx.showMessageToast("Subscribed to newsletter.", "info");
  };

  const imgStyle = {
    width: "60px",
    height: "60px",
    padding: "6px",
    borderRadius: "56px",
    backgroundColor: "var(--secondary-color)",
  };
  const nameStyle = {
    width: "160px",
    height: "32px",
  };
  return (
    <Fragment>
      <hr
        style={{
          backgroundColor: "var(--shadow-color)",
          height: "1px",
          border: "none",
        }}
      />
      <FlexContainer
        mobileMargin="2rem 0 18rem 0"
        direction="column"
        align="center"
        padding="2.4rem 1.2rem"
      >
        <FlexContainer
          wrap="wrap"
          direction="row"
          justify="space-between"
          width="80%"
          padding="0 0 0 1.2rem"
        >
          <FooterLinks>
            <Link to="/">
              <FlexContainer direction="column" align="center" justify="center">
                <img style={imgStyle} src={logo} alt="logo" />
                <img style={nameStyle} src={logo_name} alt="name" />
              </FlexContainer>
            </Link>
          </FooterLinks>
          <FooterLinks>
            <H5>REMIVIRIGN</H5>
            <Link to="/pages/about">About Us</Link>
            <Link to="/pages/contact">Contact Us</Link>
            <Link to="/pages/faqs">FAQs</Link>
          </FooterLinks>
          <FooterLinks>
            <H5>POLICY</H5>
            <Link to="/pages/terms">Terms &amp; Conditions</Link>
            <Link to="/pages/privacy">Privacy Policy</Link>
            <Link to="/pages/policy">Return Policy</Link>
            <Link to="/pages/policy">Exchange Policy</Link>
          </FooterLinks>
          <FooterLinks>
            <H5>CATEGORIES</H5>
            {categories.map((category) => (
              <Link key={category.value} to={category.to}>
                {category.title}
              </Link>
            ))}
            <Link to="/sale">On Sale</Link>
          </FooterLinks>
          <FooterLinks mobilePadding="0 2rem" width="30rem" mobileWidth="100%">
            <H5>SUBSCRIBE</H5>
            <Paragraph>
              Subscribe to our newsletter, so that you can be the first to know
              about new arrivals and exclusive offers.{" "}
            </Paragraph>
            <Subscribe onSubmit={handleSubmit} />
          </FooterLinks>
        </FlexContainer>
        <FlexContainer align="center" justify="space-around" width="100%">
          <Description>
            Copyright © 2023 RemiVirign. All rights reserved.
          </Description>
          <FlexContainer breakpoint="350px">
            <img
              title="mastercard"
              src="https://img.icons8.com/color/96/000000/mastercard.png"
              alt="mastercard"
            />
            <img
              title="visa"
              src="https://img.icons8.com/color-glass/96/000000/visa.png"
              alt="visa"
            />
            <img
              title="paypal"
              src="https://img.icons8.com/fluency/96/000000/paypal.png"
              alt="paypal"
            />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Fragment>
  );
};

export default Footer;
