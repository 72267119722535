import api from './app-controller';
import USER_ENDPOINTS from '../routes/user-routes'

// EMAIL SERVICE CONTROLLERS
export const verifyEmailController = async (token) => await api.post(USER_ENDPOINTS.VERIFY_EMAIL(token));
export const resendVerificationEmailController = async (body) => await api.post(USER_ENDPOINTS.RESEND_VERIFICATION_EMAIL, body);
// PASSWORD SERVICE CONTROLLERS
export const forgotPasswordController = async (body) => await api.post(USER_ENDPOINTS.FORGOT_PASSWORD, body);
export const resetPasswordController = async (body, token) => await api.post(USER_ENDPOINTS.RESET_PASSWORD(token), body);
// USER CONTROLLERS
export const registerUserController = async (user) => await api.post(USER_ENDPOINTS.REGISTER, user);
export const loginUserController = async (user) => await api.post(USER_ENDPOINTS.LOGIN, user);
export const getUserController = async () => await api.get(USER_ENDPOINTS.GET_USER);
export const getWishlistController = async () => await api.get(USER_ENDPOINTS.GET_WISHLIST);
export const logoutUserController = async () => await api.post(USER_ENDPOINTS.LOGOUT);
export const updateUserController = async (user) => await api.put(USER_ENDPOINTS.UPDATE_USER, user);
export const addNewAddressController = async (body) => await api.put(USER_ENDPOINTS.ADD_NEW_ADDRESS, body);//need an address param
export const deleteAddressController = async (body) => await api.put(USER_ENDPOINTS.DELETE_ADDRESS, body);//need an addressId param
export const addToWishlistController = async (body) => await api.put(USER_ENDPOINTS.ADD_TO_WISHLIST, body);
export const deleteFromWishlistController = async (body) => await api.put(USER_ENDPOINTS.DELETE_FROM_WISHLIST, body);
export const updateCurrencyController = async (body) => await api.put(USER_ENDPOINTS.UPDATE_CURRENCY, body);