import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataContext from "../../context/data-context";
import { changeCurrency } from "../../store/actions/user-actions";

import styles from "./Molecules.module.css";

const Currency = () => {
  const ctx = useContext(DataContext);
  const user = useSelector((state) => state.user.user);
  const currency = useSelector((state) => state.user.currency);
  const dispatch = useDispatch();
  const changeHandler = (e) => {
    const { value } = e.target;
    dispatch(changeCurrency(value, ctx.showMessageToast, user));
  };
  return (
    <div className={styles.currency_box}>
      <select name="currency" onChange={changeHandler} value={currency}>
        <option value="usd">$ USD</option>
        <option value="eur">€ EUR</option>
        <option value="gbp">£ GBP</option>
        <option value="inr">₹ INR</option>
      </select>
    </div>
  );
};

export default Currency;
