import React, { Fragment } from "react";
import Promo from "../../components/organisms/Promo/Promo";
import FlexContainer from "../../components/templates/FlexContainer";
import { H3, H4 } from "../../components/typography/Headings";
import { Paragraph } from "../../components/typography/Paragraphs";

import styles from "./Misc.module.css";

const About = () => {
  return (
    <Fragment>
      <H3 fontWeight="400" margin=".5rem 0" underlined>
        About Us
      </H3>
      <FlexContainer
        direction="column"
        margin="0 0 .5rem 0"
        self="center"
        maxWidth="50%"
        mobileMargin="0 0 1rem 0"
      >
        <Paragraph padding="0 .5rem">
          <strong>Maan Incorporation (India based Company)</strong> is one of
          the largest direct Virgin Indian Human Hair Extensions, Remy machine
          weft, Lace closures, Lace front exporter, wholesaler and global
          distributors of premium Virgin Indian human hair can offer you direct
          access to the finest quality Raw/Virgin Human Hair, collected from
          Indian Temples, India has to offer.
        </Paragraph>
      </FlexContainer>
      <Promo />
      <FlexContainer
        direction="column"
        margin="1rem 0"
        self="center"
        maxWidth="50%"
        mobileMargin="0 0 1rem 0"
      >
        <H4 margin="0 0 1rem 0" color="var(--color-5)">
          WE ARE BIG BELIEVERS in quality Remy-virgin hair!
        </H4>
        <Paragraph padding="0 .5rem">
          Our finest Remy Virgin Hair are available to{" "}
          <u>
            individuals buyers, salon chains, hair stylists, cosmetic / beauty
            distributors and retailers
          </u>{" "}
          of all sizes. Whether you are simply looking for product for yourself,
          you're a well established or an emerging hair brand or hair reseller
          our company is your{" "}
          <strong>
            <q>one stop</q>
          </strong>{" "}
          source for the most beautiful Virgin human hair collection available
          in the market.
        </Paragraph>
        <Paragraph margin="1rem 0" padding="0 .5rem">
          Our company proud ourselves to call us the best Remy Raw/Virgin human
          hair manufacturer company in India. We are the one Virgin human hair
          manufacturer in India who wholesale full range of our Virgin Hair
          world leading brands, Hollywood celebs brands, Leading Salons Chains.
        </Paragraph>
        <Paragraph padding="0 .5rem">
          We are licensed &amp; have direct access to the temple auctions &amp;
          exclusive agreement with small temples as well. At our company Hair we
          have a very thorough 3-tier quality inspection process ensuring you
          receive a premium product free of imperfections.
        </Paragraph>
        <Paragraph margin="1rem 0" padding="0 .5rem">
          We believe in <strong>‘NOT LIP SERVICE. CLIENT SERVICE’</strong>. We
          offer full customer support throughout the buying process and assign
          all wholesale customers an Account Executive to manage their needs.
          Whether you've a question before or after you've ordered your hair ,
          don't hesitate to reach out. We are here to help you 7 days a week and
          respond within 24 hours.
        </Paragraph>
        <Paragraph padding="0 .5rem">
          We export our exclusive products all over the world and offer a full
          range of Machine Wefts, Bulk Hair, Clip-in Hair Extensions,
          Volumizers, Lace Frontals, Lace Closures &amp; Ponytails.
          <ul className={styles.list__about}>
            We assure you for :<li>Ultimate Hair Experience.</li>
            <li>Absolutely no shedding &amp; tangling.</li>
            <li>Guaranteed Virgin Human Hair.</li>
            <li>No grey, red hair &amp; nits.</li>
            <li>No adulteration</li>
            <li>No Glue at all</li>
            <li>Generous sizing</li>
          </ul>
        </Paragraph>
        <Paragraph margin=".5rem 0" padding="0 .5rem">
          Your happiness and satisfaction with your is always at the top of our
          priority list. We pride ourselves on our customer service and being
          there with you every step of the way, from the time before you
          purchase your Pieces, through its delivery, and after.
        </Paragraph>
        <Paragraph padding="0 .5rem">
          Yes, They are 100% Virgin human hair, thick (from top to the bottom),
          long, shiny, beautiful, and definitely sexy locks! And guess what! The
          best part is that they are super affordable than available in Market!
        </Paragraph>
        <Paragraph padding="0 .5rem">
          Cost a fraction of what other hair extension companies charge for a
          similar set. We absolutely{" "}
          <strong>
            <em>Love</em>
          </strong>{" "}
          them and we are sure you will{" "}
          <strong>
            <em>Love</em>
          </strong>{" "}
          them too!
        </Paragraph>
      </FlexContainer>
    </Fragment>
  );
};

export default About;
