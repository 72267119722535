import { Link as Links } from "react-router-dom";
import styled from "styled-components";

const Link = styled(Links)`
  &:link,
  &:visited {
    text-decoration: none;
    color: ${(props) => props.color || "var(--primary-text-color)"};
    font-size: ${(props) => props.fontSize || "1.2rem"};
    width: ${(props) => props.width || "auto"};
    margin: ${(props) => props.margin || "0"};
    font-weight: ${(props) => props.fontWeight || "300"};
    text-transform: ${(props) => props.textTransform || "none"};
    position: relative;
  }
  &:hover {
    text-decoration: ${(props) => props.underline || "underline"};
  }
  @media (max-width: 768px) {
    font-size: ${(props) => props.mobileFontSize || "1rem"};
  }
`;

export default Link;
