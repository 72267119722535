import React, { useContext, useRef } from "react";
import DataContext from "../../../context/data-context";
import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import Button from "../../atoms/Button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";

import styles from "./LoginForm.module.css";

// Functionalities:
import { loginUser } from "../../../store/actions/user-actions";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";

export const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorToastRef = useRef(null);
  const dataCtx = useContext(DataContext);
  const validate = (data) => {
    let errors = {};

    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = "Invalid email address. E.g. example@email.com";
    }

    if (!data.password) {
      errors.password = "Password is required.";
    }

    return errors;
  };

  const showMessageToast = (message, type) => {
    dataCtx.showMessageToast(message, type);
  };

  const onSubmit = (data, form) => {
    dispatch(loginUser(data, form, showMessageToast, navigate));
    // form.reset();
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>
    );
  };

  return (
    <div className={styles["form-demo"]}>
      <Toast ref={errorToastRef} />
      <div className="flex justify-content-center">
        <div className={styles["card"]}>
          <h5 className={styles["text-center"]}>Login</h5>
          <Form
            onSubmit={onSubmit}
            initialValues={{ email: "", password: "" }}
            validate={validate}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="p-fluid">
                <Field
                  name="email"
                  render={({ input, meta }) => (
                    <div className={styles["field"]}>
                      <span className="p-float-label p-input-icon-right">
                        <i className="pi pi-envelope" />
                        <InputText
                          id="email"
                          {...input}
                          className={classNames({
                            "p-invalid": isFormFieldValid(meta),
                          })}
                        />
                        <label
                          htmlFor="email"
                          className={classNames({
                            "p-error": isFormFieldValid(meta),
                          })}
                        >
                          Email*
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Field
                  name="password"
                  render={({ input, meta }) => (
                    <div className={styles["field"]}>
                      <span className="p-float-label">
                        <Password
                          id="password"
                          {...input}
                          toggleMask
                          className={classNames({
                            "p-invalid": isFormFieldValid(meta),
                          })}
                        />
                        <label
                          htmlFor="password"
                          className={classNames({
                            "p-error": isFormFieldValid(meta),
                          })}
                        >
                          Password*
                        </label>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}
                />
                <Button type="submit" width="100%" padding=".6rem 0" secondary>
                  Login
                </Button>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
};
